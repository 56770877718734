import { useCallback, useEffect } from 'react';
import { m } from 'framer-motion';
// @mui
import MenuItem from '@mui/material/MenuItem';
import IconButton from '@mui/material/IconButton';
// locales
import { Typography } from '@mui/material';
// components
import Iconify from 'src/components/iconify';
import { varHover } from 'src/components/animate';
import CustomPopover, { usePopover } from 'src/components/custom-popover';
import { useAuthContext } from 'src/auth/hooks';
import { useResponsive } from 'src/hooks/use-responsive';
import { useLocationModuleLocal } from 'src/hooks/use-location-module-local';
import { useGetClientInfoCallback } from 'src/api/client';
import { enqueueSnackbar } from 'src/components/snackbar';

// ----------------------------------------------------------------------

export default function ClientPopover() {
  // const clients = [
  //   {
  //     value: 'client1',
  //     label: 'Client 1',
  //   },
  //   {
  //     value: 'client2',
  //     label: 'Client 2',
  //   },
  // ];
  const popover = usePopover();
  const { user } = useAuthContext();

  const getClientInfo = useGetClientInfoCallback();

  const getClient = useCallback(() => {
    getClientInfo({
      page_no: 1,
      input_row: 10,
    })
      .then((res) => {
        console.log(res);
      })
      .catch((err) => {
        enqueueSnackbar(JSON.stringify(err.message), { variant: 'error' });
      });
  }, [getClientInfo]);

  useEffect(() => {
    getClient();
  }, [getClient]);

  const upMd = useResponsive('up', 'md');

  const handleChangeLang = useCallback(
    (newLang: string) => {
      // locales.onChangeLang(newLang);
      popover.onClose();
    },
    [popover]
  );

  const { sites, areas, zones, refreshSites, refreshAreas, refreshZones } =
    useLocationModuleLocal();

  return (
    <>
      {upMd && (
        <Typography
          sx={{
            opacity: 0.64,
          }}
          variant="subtitle2"
        >
          {user?.site_id}
        </Typography>
      )}
      <IconButton
        component={m.button}
        whileTap="tap"
        whileHover="hover"
        variants={varHover(1.05)}
        onClick={popover.onOpen}
        sx={{
          width: 40,
          height: 40,
          ...(popover.open && {
            bgcolor: 'action.selected',
          }),
        }}
      >
        <Iconify icon="ph:devices-duotone" sx={{ borderRadius: 0.65, width: 28 }} />
      </IconButton>

      <CustomPopover open={popover.open} onClose={popover.onClose} sx={{ width: 'max-content' }}>
        {sites.map((option, index) => (
          <MenuItem
            key={option.site_id}
            selected={option.site_id === user?.site_id}
            onClick={() => handleChangeLang(option.site_id)}
          >
            <Iconify icon="ph:devices-duotone" sx={{ borderRadius: 0.65, width: 28 }} />
            <Typography variant="subtitle2" sx={{ opacity: 0.64 }}>
              {option.address1_en}
            </Typography>
          </MenuItem>
        ))}
      </CustomPopover>
    </>
  );
}
