export const STORAGE_TOKEN = 'accessToken';
export const STORAGE_REFRESH_TOKEN = 'refreshToken';
export const ENCRYPTION_TYPE = [
  {
    label: 'None',
    value: 0,
  },
  {
    label: 'SSL',
    value: 1,
  },
  {
    label: 'TLS',
    value: 2,
  },
];
// 默认 floormap 放大的级别
export const DEFAULT_ZOOM_OUT = 10;
// floormap  最小放大级别
export const MIN_ZOOM_OUT = 10;

// 点击 floormap point 时，放大的级别
export const CLICK_POINT_ZOOM_OUT = 10;
