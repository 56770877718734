import axios, { AxiosRequestConfig } from 'axios';
// config
import { HOST_API } from 'src/config-global';

// ----------------------------------------------------------------------

const axiosInstance = axios.create({ baseURL: HOST_API });

axiosInstance.interceptors.response.use(
  (res) => res,
  (error) => Promise.reject((error.response && error.response.data) || 'Something went wrong')
);

export default axiosInstance;

// ----------------------------------------------------------------------

export const fetcher = async (args: string | [string, AxiosRequestConfig]) => {
  const [url, config] = Array.isArray(args) ? args : [args];

  const res = await axiosInstance.get(url, { ...config });

  return res.data;
};

// ----------------------------------------------------------------------

export type RESPONSE<T> = {
  code: string;
  info: T;
  message: string;
};

// ----------------------------------------------------------------------

export const _endpoints = {
  auth: {
    login: '/api/Login/UserLogin',
    regreshToken: '/api/Login/RefreshToken',
  },
  dashboard: {
    get: '/api/dashboard/getDashboard',
    update: '/api/dashboard/updateDashboard',
    sites: '/api/dashboard/getSite',
    areas: '/api/dashboard/getSiteArea',
    zones: '/api/dashboard/getSiteZone',
  },
  floorplan: {
    list: '/api/floorPlan/getFloorPlan',
    details: '/api/floorPlan/getFloorPlanByMapID',
    create: '/api/floorPlan/createFloorPlan',
    update: '/api/floorPlan/updateFloorPlan',
    points: '/api/floorPlan/getFloorPlanPoints',
    createPoint: '/api/floorPlan/createFloorPlanPoints',
    updatePoint: '/api/floorPlan/updateFloorPlanPoints',
    getFloorPlanPoints: '/api/floorPlan/getFloorPlanPoints',
    getPointItem: '/api/floorPlan/getFloorPlanPointItems',
    createPointItem: '/api/floorPlan/createFloorPlanPointItems',
    updatePointItem: '/api/floorPlan/updateFloorPlanPointItems',
    deletePointItem: '/api/floorPlan/deleteFloorPlanPointItems',
    displayType: '/api/floorPlan/getIOTDisplayType',
    displayPeriod: '/api/floorPlan/getIOTDisplayPeriod',
    displayFigure: '/api/floorPlan/getFloorPlanFigure',
  },
  gadget: {
    create: '/api/dashboard/createGadget',
    update: '/api/dashboard/updateGadget',
    delete: '/api/dashboard/deleteGadget',
    list: '/api/dashboard/getGadgetList',
    details: '/api/dashboard/getGadgetDetails',
  },
  report: {
    create: '/api/report/createReport',
    update: '/api/report/updateReport',
    remove: '/api/report/removeReport',
    list: '/api/report/getReportList',
    byId: '/api/report/triggerReportByID',
    getReportPeriod: '/api/report/getReportPeriod',
    getReportMetric: '/api/report/getReportMetric',
    getReportCalculationType: '/api/report/getReportCalculationType',
    getReportChartType: '/api/report/getReportChartType',
    getReportSensorGroup: '/api/report/getReportSensorGroup',
    getReportLocationGroup: '/api/report/getReportLocationGroup',
  },
  site: {
    dashboard_get: '/api/dashboard/getSite',
    list: '/api/Client/getSiteList',
    details: '/api/Client/getSite',
    create: '/api/Client/createSite',
    update: '/api/Client/updateSite',
  },
  area: {
    dashboard_get_site_area: '/api/dashboard/getSiteArea',
    list: '/api/Client/getAreaList',
    details: '/api/Client/getArea',
    create: '/api/Client/createArea',
    update: '/api/Client/updateArea',
  },
  zone: {
    dashboard_get_site_zone: '/api/Dashboard/getSiteZone',
    list: '/api/Client/getZoneList',
    details: '/api/Client/getZone',
    create: '/api/Client/createZone',
    update: '/api/Client/updateZone',
  },
  displayUnit: {
    dashboard_get: '/api/dashboard/getGeneralDisplayUnit',
  },
  periodType: {
    dashboard_get: '/api/dashboard/getPeriodType',
    dashboard_general_get: '/api/dashboard/getGeneralPeriodType',
  },
  calculationType: {
    dashboard_get: '/api/dashboard/getGeneralCalculationType',
  },
  dataType: {
    dashboard_get: '/api/dashboard/getGeneralDataType',
  },
  user: {
    get: 'api/user/getUser',
    list: '/api/user/getUserList',
    details: '/api/user/getUserDetails',
    create: '/api/user/createUser',
    update: '/api/user/updateUser',
    delete: '/api/user/deleteUser',
    list_department: '/api/User/getDepartmentList',
    list_usertype: '/api/User/getUserTypeList',
    list_userrole: '/api/User/getUserRoleList',
    getUserGroupList: '/api/User/getUserGroupList',
    createUserGroup: '/api/User/createUserGroup',
    updateUserGroup: '/api/User/updateUserGroup',
    createUserGroupMember: '/api/User/createUserGroupMember',
    updateUserGroupMember: '​/api​/User​/updateUserGroupMember',
    removeUserGroupMember: '​/api​/User​/removeUserGroupMember',
  },
  device: {
    get: '/api/device/getDeviceInfo',
    list: '/api/device/getDeviceList',
    type: '/api/device/getDeviceType',
    create: '/api/device/createDevice',
    update: '/api/device/updateDevice',
    retrieve_key: '/api/device/retrieveSensorLicenseKey',
    import_key: '/api/device/importSensorlicenseKey',
    line: '/api/device/getDeviceLinebyDeviceID',
  },
  group: {
    create: '/api/device/createDeviceGroup',
    update: '/api/device/updateDeviceGroup',
    list: '/api/device/getDeviceGroup',
    details: '/api/device/getDeviceGroupItem',
    add_device_2_group: '/api/device/addDevice2Group',
    remove_device_4_group: '/api/device/removeDevice4Group',
    get_member_list: '/api/device/getDeviceGroupMemberList',
    add_member_list: '/api/device/addDeviceGroupMemberList',
    update_member_list: '/api/device/updateDeviceGroupMemberList',
    remove_member_list: '/api/device/removeDeviceGroupMemberList',
    is_admin_service: '/api/device/isDeviceGroupAdminService',
    is_member_service: '/api/device/isDeviceGroupMemberService',
    get_role: '/api/device/getDeviceGroupRole',
  },
  client: {
    get: '/api/Client/getClientbyClientID',
  },
  email: {
    test: '/api/Email/testMail',
    get: '/api/Email/getSMTPConfig',
    update: '/api/Email/updateSMTPConfig',
  },
};
