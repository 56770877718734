import { Suspense } from 'react';
import { Outlet } from 'react-router-dom';
import { SplashScreen } from 'src/components/loading-screen';
import MainLayout from 'src/layouts/main';
import { authRoutes } from './auth';
import { dashboardRoutes } from './dashboard';
import { mainRoutes, HomePage } from './main';
import { componentsRoutes } from './components';

export const demoRoutes = [
  {
    path: 'demo',
    // element: (
    //   <MainLayout>
    //     <HomePage />
    //   </MainLayout>
    // ),
    element: (
      <MainLayout>
        <Suspense fallback={<SplashScreen />}>
          <Outlet />
        </Suspense>
      </MainLayout>
    ),
    children: [...authRoutes, ...dashboardRoutes, ...mainRoutes, ...componentsRoutes],
  },
];
