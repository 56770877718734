import { useCallback } from 'react';
// utils
import { AxiosResponse } from 'axios';
import _axios, { _endpoints } from 'src/utils/_axios';
// types
import { IGetReportListParams } from 'src/types/report';
// hooks
import { useAuthContext } from '../auth/hooks';
import { useLocales } from '../locales';

// ----------------------------------------------------------------------

/**
 * 创建 report 数据
 */
export const useCreateReportCallback = () => {
  const auth = useAuthContext();
  const { t } = useLocales();

  return useCallback(
    // todo
    (report: any) =>
      new Promise<AxiosResponse>((resolve, reject) => {
        if (!auth.user) {
          reject(new Error(t('User is not logged in')));
          return;
        }

        const params = {
          // client_id: auth.user.client_id as string,
          // email: auth.user.email as string,
          // verify_key: auth.user.verify_key as string,
        };

        const data = {
          client_id: auth.user.client_id as string,
          user_id: auth.user.email as string,
          ...report,
        };

        _axios
          .post(_endpoints.report.create, data, {
            params,
          })
          .then((res) => {
            resolve(res);
          })
          .catch((err) => {
            reject(err);
          });
      }),
    [auth.user, t]
  );
};

/**
 * 更新 report 数据
 */
export const useUpdateReportCallback = () => {
  const auth = useAuthContext();
  const { t } = useLocales();

  return useCallback(
    // todo
    (report: any) =>
      new Promise<AxiosResponse>((resolve, reject) => {
        if (!auth.user) {
          reject(new Error(t('User is not logged in')));
          return;
        }

        // const params = {
        //   client_id: auth.user.client_id as string,
        //   email: auth.user.email as string,
        //   verify_key: auth.user.verify_key as string,
        // };

        const data = {
          ...report,
          client_id: auth.user.client_id as string,
          user_id: auth.user.email as string,
        };

        _axios
          .post(_endpoints.report.update, data)
          .then((res) => {
            resolve(res);
          })
          .catch((err) => {
            reject(err);
          });
      }),
    [auth.user, t]
  );
};

/**
 * 查詢 report 列表
 */
export const fetchReportList = async (params: Partial<IGetReportListParams>) => {
  const response = await _axios.get(_endpoints.report.list, {
    params,
  });
  return response.data;
};

// 删除 report
export const removeReport = async (report_id: string, report_sensor_type_id: number) => {
  const response = await _axios.post(_endpoints.report.remove, {
    report_id,
    report_sensor_type_id,
  });
  return response.data;
};

// 获取表单中的 Metric
export const getMetric = async () => {
  const response = await _axios.get(_endpoints.report.getReportMetric);
  return response.data;
};
// 获取表单中的period
export const getPeriod = async () => {
  const response = await _axios.get(_endpoints.report.getReportPeriod);
  return response.data;
};

// 获取表单中的CalculationType
export const getCalculationType = async () => {
  const response = await _axios.get(_endpoints.report.getReportCalculationType);
  return response.data;
};

// 获取表单中的chartType
export const getChartType = async () => {
  const response = await _axios.get(_endpoints.report.getReportChartType);
  return response.data;
};

// 获取report中的数据源
export const getReportSensorGroup = async (report_id: string) => {
  const response = await _axios.get(_endpoints.report.getReportSensorGroup, {
    params: {
      report_id,
    },
  });
  return response.data;
};
export const getReportLocationGroup = async (report_id: string) => {
  const response = await _axios.get(_endpoints.report.getReportLocationGroup, {
    params: {
      report_id,
    },
  });
  return response.data;
};
