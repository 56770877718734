import { useCallback, useMemo } from 'react';
import useSWR from 'swr';
import { AxiosResponse } from 'axios';
import { useAuthContext } from 'src/auth/hooks';
import { IGadgetItem } from 'src/types/gadget';
import _axios, { _endpoints, fetcher } from 'src/utils/_axios';
import { IUserItem, IGetUserGroupApiQuery, ICreateUserGroupApiData } from 'src/types/user';
import { useLocales } from 'src/locales';

export function useGetUsers() {
  const auth = useAuthContext();

  const URL = useMemo(
    () =>
      auth.user
        ? [
            _endpoints.user.list,
            {
              params: {
                client_id: auth.user.client_id as string,
                email: auth.user.email as string,
                verify_key: auth.user.verify_key as string,
              },
            },
          ]
        : null,
    [auth.user]
  );

  const { data, isLoading, error, isValidating } = useSWR(URL, fetcher);

  return useMemo(
    () => ({
      users: (data as IGadgetItem[]) || [],
      usersLoading: isLoading,
      usersError: error,
      usersValidating: isValidating,
      usersEmpty: !isLoading && !data?.length,
    }),
    [data, error, isLoading, isValidating]
  );
}

// ----------------------------------------------------------------------

/**
 * 创建 user 数据
 */
export const useCreateUserCallback = () => {
  const auth = useAuthContext();
  const { t } = useLocales();

  return useCallback(
    (user: IUserItem) =>
      new Promise<AxiosResponse>((resolve, reject) => {
        if (!auth.user) {
          reject(new Error(t('User is not logged in')));
          return;
        }

        const params = {
          client_id: auth.user.client_id as string,
          email: auth.user.email as string,
          verify_key: auth.user.verify_key as string,
        };

        const data = { ...user };

        _axios
          .post(_endpoints.user.create, data, {
            params,
          })
          .then((res) => {
            resolve(res);
          })
          .catch((err) => {
            reject(err);
          });
      }),
    [auth.user, t]
  );
};

// ----------------------------------------------------------------------

/**
 * 更新 user 数据
 */
export const useUpdateUserCallback = () => {
  const auth = useAuthContext();
  const { t } = useLocales();

  return useCallback(
    (user: Partial<IUserItem>) =>
      new Promise<AxiosResponse>((resolve, reject) => {
        if (!auth.user) {
          reject(new Error(t('User is not logged in')));
          return;
        }

        const params = {
          client_id: auth.user.client_id as string,
          email: auth.user.email as string,
          verify_key: auth.user.verify_key as string,
        };

        const data = { ...user };

        _axios
          .post(_endpoints.user.update, data, {
            params,
          })
          .then((res) => {
            resolve(res);
          })
          .catch((err) => {
            reject(err);
          });
      }),
    [auth.user, t]
  );
};

// ----------------------------------------------------------------------

/**
 * 获取 用户 列表数据
 */
export const useGetUserListCallback = () => {
  const auth = useAuthContext();
  const { t } = useLocales();

  return useCallback(
    (pageNo = 1, inputRow = 10, utc = 0) =>
      new Promise<AxiosResponse>((resolve, reject) => {
        if (!auth.user) {
          reject(new Error(t('User is not logged in')));
          return;
        }

        const params = {
          client_id: auth.user.client_id as string,
          email: auth.user.email as string,
          verify_key: auth.user.verify_key as string,
          page_no: pageNo,
          input_row: inputRow,
          utc,
        };

        _axios
          .get(_endpoints.user.list, {
            params,
          })
          .then((res) => {
            if (res.data.code !== '1') {
              reject(new Error(res.data.message || 'Unknown error'));
              return;
            }

            resolve(res);
          })
          .catch((err) => {
            reject(err);
          });
      }),
    [auth.user, t]
  );
};

// ----------------------------------------------------------------------

/**
 * 获取 deparment 列表数据
 */
export const useGetUserDepartmentListCallback = () => {
  const auth = useAuthContext();
  const { t } = useLocales();

  return useCallback(
    (pageNo = 1, inputRow = 10, utc = 0) =>
      new Promise<AxiosResponse>((resolve, reject) => {
        if (!auth.user) {
          reject(new Error(t('User is not logged in')));
          return;
        }

        const params = {
          client_id: auth.user.client_id as string,
          email: auth.user.email as string,
          verify_key: auth.user.verify_key as string,
          page_no: pageNo,
          input_row: inputRow,
          utc,
        };

        _axios
          .get(_endpoints.user.list_department, {
            params,
          })
          .then((res) => {
            if (res.data.code !== '1') {
              reject(new Error(res.data.message || 'Unknown error'));
              return;
            }

            resolve(res);
          })
          .catch((err) => {
            reject(err);
          });
      }),
    [auth.user, t]
  );
};

// swr 写法， 暂不使用
// export function useGetUserDepartment(pageNo = 1, inputRow = 10, utc = 0) {
//   const auth = useAuthContext();

//   const URL = useMemo(
//     () =>
//       auth.user
//         ? [
//             _endpoints.user.list_department,
//             {
//               params: {
//                 client_id: auth.user.client_id as string,
//                 email: auth.user.email as string,
//                 verify_key: auth.user.verify_key as string,
//                 page_no: pageNo,
//                 input_row: inputRow,
//                 utc,
//               },
//             },
//           ]
//         : null,
//     [auth.user, inputRow, pageNo, utc]
//   );

//   const { data, isLoading, error, isValidating, mutate } = useSWR(URL, fetcher);

//   return useMemo(
//     () => ({
//       deparments: (data?.rows as IUserDepartmentsItem[]) || [],
//       deparmentsLoading: isLoading,
//       deparmentsError: error,
//       deparmentsValidating: isValidating,
//       deparmentsEmpty: !isLoading && !data?.length,
//       deparmentsMutate: mutate,
//     }),
//     [data, error, isLoading, isValidating, mutate]
//   );
// }

// ----------------------------------------------------------------------

/**
 * 获取 usertype 列表数据
 */
export const useGetUserTypeListCallback = () => {
  const auth = useAuthContext();
  const { t } = useLocales();

  return useCallback(
    (pageNo = 1, inputRow = 10, utc = 0) =>
      new Promise<AxiosResponse>((resolve, reject) => {
        if (!auth.user) {
          reject(new Error(t('User is not logged in')));
          return;
        }

        const params = {
          client_id: auth.user.client_id as string,
          email: auth.user.email as string,
          verify_key: auth.user.verify_key as string,
          page_no: pageNo,
          input_row: inputRow,
        };

        _axios
          .get(_endpoints.user.list_usertype, {
            params,
          })
          .then((res) => {
            if (res.data.code !== '1') {
              reject(new Error(res.data.message || 'Unknown error'));
              return;
            }

            resolve(res);
          })
          .catch((err) => {
            reject(err);
          });
      }),
    [auth.user, t]
  );
};

// ----------------------------------------------------------------------

/**
 * 获取 userrole 列表数据
 */
export const useGetUserRoleListCallback = () => {
  const auth = useAuthContext();
  const { t } = useLocales();

  return useCallback(
    (pageNo = 1, inputRow = 10, utc = 0) =>
      new Promise<AxiosResponse>((resolve, reject) => {
        if (!auth.user) {
          reject(new Error(t('User is not logged in')));
          return;
        }

        const params = {
          client_id: auth.user.client_id as string,
          email: auth.user.email as string,
          verify_key: auth.user.verify_key as string,
          page_no: pageNo,
          input_row: inputRow,
        };

        _axios
          .get(_endpoints.user.list_userrole, {
            params,
          })
          .then((res) => {
            if (res.data.code !== '1') {
              reject(new Error(res.data.message || 'Unknown error'));
              return;
            }

            resolve(res);
          })
          .catch((err) => {
            reject(err);
          });
      }),
    [auth.user, t]
  );
};

// 获取userGroupList
export const getUserGroupList = async (query: Partial<IGetUserGroupApiQuery>) => {
  const response = await _axios.get(_endpoints.user.getUserGroupList, {
    params: query,
  });
  return response.data;
};
// 创建 userGroup
export const createUserGroup = async (data: Partial<ICreateUserGroupApiData>) => {
  const response = await _axios.post(_endpoints.user.createUserGroup, data);
  return response.data;
};
// 更新 userGroup
export const updateUserGroup = async (data: Partial<ICreateUserGroupApiData>) => {
  const response = await _axios.post(_endpoints.user.updateUserGroup, data);
  return response.data;
};

// 获取用户列表， useSwr
export function useGetUserList(pageNo = 1, inputRow = 10, utc = 0) {
  const auth = useAuthContext();
  const params = {
    client_id: auth?.user?.client_id as string,
    email: auth?.user?.email as string,
    verify_key: auth?.user?.verify_key as string,
    page_no: pageNo,
    input_row: inputRow,
    utc,
  };

  const URL = [_endpoints.user.list, { params }];

  const { data, isLoading, error, isValidating } = useSWR(URL, fetcher);

  const memoizedValue = useMemo(
    () => ({
      userList: data,
      isLoading,
      error,
      isValidating,
    }),
    [data, error, isLoading, isValidating]
  );

  return memoizedValue;
}
