import { createSlice } from '@reduxjs/toolkit';
import { IUserGroups } from 'src/types/user';
import { fetchUserGroupList } from './actions';

export type IUserGroupState = {
  list: {
    loading: boolean;
    rows: IUserGroups;
    row_count: number;
  };
};

const initialState: IUserGroupState = {
  list: {
    loading: false,
    rows: [],
    row_count: 0,
  },
};
const slice = createSlice({
  name: 'userGroup',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      // * userGroupList
      .addCase(fetchUserGroupList.pending, (state) => {
        state.list.loading = true;
      })
      .addCase(fetchUserGroupList.fulfilled, (state, action) => {
        state.list = {
          loading: false,
          ...action.payload,
        };
      })
      .addCase(fetchUserGroupList.rejected, (state, action) => {
        state.list.loading = false;
      });
  },
});

export const reportActions = slice.actions;
export default slice.reducer;
