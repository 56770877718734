import { createAsyncThunk } from '@reduxjs/toolkit';
import _ from 'lodash';
import { getUserGroupList } from 'src/api/user';
import { IGetUserGroupApiQuery } from 'src/types/user';

export interface FetchUserGroupListPayload {
  // 可以在这里定义请求参数
  payload: Partial<IGetUserGroupApiQuery>;
}

export const fetchUserGroupList = createAsyncThunk<
  any, // 返回的数据类型
  FetchUserGroupListPayload // 传入的参数类型
>('report/fetchUserGroupList', async ({ payload }, { getState }) => {
  const state = getState();
  const user = _.get(state, 'user.user');

  const params = {
    email: _.get(user, 'email'),
    client_id: _.get(user, 'client_id'),
    verify_key: _.get(user, 'verify_key'),
    ...payload,
  };
  const response = await getUserGroupList(params);
  return response;
});
