import { lazy } from 'react';
// ----------------------------------------------------------------------

// OVERVIEW
/**
 * lazy: lazy load components
 */
const DashboardPage = lazy(() => import('src/pages/application/dashboard'));
const ReportPage = lazy(() => import('src/pages/application/report'));
const CustomPage = lazy(() => import('src/pages/application/custom'));
const FloorPlanPage = lazy(() => import('src/pages/application/floorPlan'));
const FloorPlanMap = lazy(() => import('src/pages/application/floorPlanMap'));

// ----------------------------------------------------------------------

export const applicationRoutes = [
  { path: 'dashboard', element: <DashboardPage />, index: true },
  { path: 'report', element: <ReportPage />, index: true },
  { path: 'floorplan', element: <FloorPlanPage />, index: true },
  { path: 'floorplan/:id', element: <FloorPlanMap />, index: true },
  { path: ':id/floorplan', element: <FloorPlanPage />, index: true },
  { path: 'custom', element: <CustomPage />, index: true },
];
