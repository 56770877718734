import { createSlice } from '@reduxjs/toolkit';
import {
  IReportItem,
  IReportMetricItem,
  IReportProidItem,
  IReportCalculationTypeItem,
  IReportChartTypeItem,
} from 'src/types/report';
import {
  getReportList,
  fetchMetric,
  fetchPeriod,
  fetchCalculationType,
  fetchChartType,
  removeReports,
} from './actions';

export type IReportState = {
  list: {
    loading: boolean;
    data: IReportItem[];
  };
  metric: {
    loading: boolean;
    data: IReportMetricItem[];
  };
  period: {
    loading: boolean;
    data: IReportProidItem[];
  };
  calculationType: {
    loading: boolean;
    data: IReportCalculationTypeItem[];
  };
  chartType: {
    loading: boolean;
    data: IReportChartTypeItem[];
  };
};

const initialState: IReportState = {
  list: {
    loading: false,
    data: [],
  },
  metric: {
    loading: false,
    data: [],
  },
  period: {
    loading: false,
    data: [],
  },
  calculationType: {
    loading: false,
    data: [],
  },
  chartType: {
    loading: false,
    data: [],
  },
};
const slice = createSlice({
  name: 'report',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      // * dataList
      .addCase(getReportList.pending, (state) => {
        state.list.loading = true;
      })
      .addCase(getReportList.fulfilled, (state, action) => {
        state.list.loading = false;
        // @ts-ignore
        state.list.data = action.payload;
      })
      .addCase(getReportList.rejected, (state, action) => {
        state.list.loading = false;
      })
      // * removeReports
      .addCase(removeReports.pending, (state) => {
        state.list.loading = true;
      })
      .addCase(removeReports.fulfilled, (state, action) => {
        const _list = state.list.data.filter((item) => !action.payload.includes(item.report_id));
        state.list.loading = false;
        state.list.data = _list;
      })
      .addCase(removeReports.rejected, (state, action) => {
        state.list.loading = false;
      })
      // * metric
      .addCase(fetchMetric.pending, (state) => {
        state.metric.loading = true;
      })
      .addCase(fetchMetric.fulfilled, (state, action) => {
        state.metric.loading = false;
        state.metric.data = action.payload;
      })
      .addCase(fetchMetric.rejected, (state, action) => {
        state.metric.loading = false;
      })
      // * period
      .addCase(fetchPeriod.pending, (state) => {
        state.period.loading = true;
      })
      .addCase(fetchPeriod.fulfilled, (state, action) => {
        state.period.loading = false;
        state.period.data = action.payload;
      })
      .addCase(fetchPeriod.rejected, (state, action) => {
        state.period.loading = false;
      })
      // * calculationType
      .addCase(fetchCalculationType.pending, (state) => {
        state.calculationType.loading = true;
      })
      .addCase(fetchCalculationType.fulfilled, (state, action) => {
        state.calculationType.loading = false;
        state.calculationType.data = action.payload;
      })
      .addCase(fetchCalculationType.rejected, (state, action) => {
        state.calculationType.loading = false;
      })
      // * chartType
      .addCase(fetchChartType.pending, (state) => {
        state.chartType.loading = true;
      })
      .addCase(fetchChartType.fulfilled, (state, action) => {
        state.chartType.loading = false;
        state.chartType.data = action.payload;
      })
      .addCase(fetchChartType.rejected, (state, action) => {
        state.chartType.loading = false;
      });
  },
});

export const reportActions = slice.actions;
export default slice.reducer;
