import { useEffect, useMemo } from 'react';
// routes
import { paths } from 'src/routes/paths';
// locales
import { useLocales } from 'src/locales';
// components
import SvgColor from 'src/components/svg-color';

// ----------------------------------------------------------------------

const icon = (name: string) => (
  <SvgColor src={`/assets/icons/navbar/${name}.svg`} sx={{ width: 1, height: 1 }} />
  // OR
  // <Iconify icon="fluent:mail-24-filled" />
  // https://icon-sets.iconify.design/solar/
  // https://www.streamlinehq.com/icons
);

const ICONS = {
  job: icon('ic_job'),
  blog: icon('ic_blog'),
  chat: icon('ic_chat'),
  mail: icon('ic_mail'),
  user: icon('ic_user'),
  file: icon('ic_file'),
  lock: icon('ic_lock'),
  tour: icon('ic_tour'),
  order: icon('ic_order'),
  label: icon('ic_label'),
  blank: icon('ic_blank'),
  kanban: icon('ic_kanban'),
  folder: icon('ic_folder'),
  banking: icon('ic_banking'),
  booking: icon('ic_booking'),
  invoice: icon('ic_invoice'),
  product: icon('ic_product'),
  calendar: icon('ic_calendar'),
  disabled: icon('ic_disabled'),
  external: icon('ic_external'),
  menuItem: icon('ic_menu_item'),
  ecommerce: icon('ic_ecommerce'),
  analytics: icon('ic_analytics'),
  dashboard: icon('ic_dashboard'),
  group: icon('group'),
  userGroup: icon('userGroup'),
  device: icon('device'),
  server: icon('server'),
  site: icon('ic_site'),
  area: icon('ic_area'),
  zone: icon('ic_zone'),
};

// ----------------------------------------------------------------------

export function useNavData() {
  const { t } = useLocales();

  const data = useMemo(
    () => [
      // OVERVIEW
      // ----------------------------------------------------------------------
      {
        subheader: t('application'),
        items: [
          {
            title: t('dashboard'),
            path: paths.application.dashboard,
            icon: ICONS.dashboard,
          },
          {
            title: t('report'),
            path: paths.application.report,
            icon: ICONS.blog,
          },
          // {
          //   title: t('floorplan'),
          //   path: paths.application.floorplan,
          //   icon: ICONS.analytics,
          // },
          {
            title: t('floorplan'),
            path: paths.application.floorplan.root,
            icon: ICONS.analytics,
            // children: treeDatas, // mock 多级菜单
            // 遍历 datas 动态加载 floorplan 菜单
            // children: datas.map((item: IFloorPlanItem) => ({
            //   title: item.name,
            //   path: `${paths.application.floorplan}/${item.id}`,
            //   icon: ICONS.analytics,
            //   children: item.children.map((item: IFloorPlanItem) => ({
            //     title: item.name,
            //     path: `${paths.application.floorplan}/${item.id}`,
            //     icon: ICONS.analytics,
            //   })),
            // })),
          },
          // {
          //   title: 'ffff',
          //   path: paths.application.floorplan.details,
          //   icon: ICONS.analytics,
          // },
          // {
          //   title: t('custom'),
          //   path: paths.application.custom,
          //   icon: ICONS.menuItem,
          // },
        ],
      },
      {
        subheader: t('management'),
        items: [
          {
            title: t('user'),
            path: paths.management.user.list,
            icon: ICONS.user,
          },
          {
            title: t('userGroup'),
            path: paths.management.userGroup,
            icon: ICONS.userGroup,
          },
          {
            title: t('device'),
            path: paths.management.device,
            icon: ICONS.device,
          },
          {
            title: t('group'),
            path: paths.management.deviceGroup,
            icon: ICONS.group,
          },
        ],
      },
      {
        subheader: t('location'),
        items: [
          {
            title: t('site'),
            path: paths.location.site,
            icon: ICONS.site,
          },
          {
            title: t('area'),
            path: paths.location.area,
            icon: ICONS.area,
          },
          {
            title: t('zone'),
            path: paths.location.zone,
            icon: ICONS.zone,
          },
        ],
      },
      {
        subheader: t('configuration'),
        items: [
          {
            title: t('server'),
            path: paths.configuration.server,
            icon: ICONS.server,
          },
        ],
      },
    ],
    [t]
  );

  return data;
}
